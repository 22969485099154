import React, {FunctionComponent} from 'react'
import {Box, Container, Flex, Heading, Stack} from '@chakra-ui/react'
import TopCard from './TopCard'

interface Props {
  error: Error
}

const ErrorCard: FunctionComponent<Props> = ({error}) => (
  <Container maxW={'container.lg'} pt={12}>
    <TopCard title={'Error'}>
      {JSON.stringify(error)}
    </TopCard>
  </Container>
)

export default ErrorCard
