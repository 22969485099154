import React, {FunctionComponent, useCallback, useMemo} from 'react'
import {
  Badge,
  Box,
  Button,
  Drawer, DrawerBody,
  DrawerCloseButton,
  DrawerContent, DrawerHeader,
  DrawerOverlay, Flex, Heading,
  HStack,
  Input,
  MenuItem,
  Stack, StackDivider, Text, Tooltip, useDisclosure,
} from '@chakra-ui/react'
import {Example, examples} from '../examples'
import {Field, Form, Formik} from 'formik'
import TopCard from '../../../components/TopCard'
import ExampleItem from './ExampleItem'
import {
  LocationClassificationResult,
  LocationClassificationTerm,
  LocationClassificationTermClassification,
} from '../../../apis/indoqa-demos'

interface Props {
  classifications?: LocationClassificationTermClassification[]
}

const LocationClassificationResultClassification = ({classifications}: Props) => {
  if (!classifications || classifications.length === 0) {
    return null
  }


  return (
        <Flex gap={1}>
          <Badge variant='subtle' colorScheme='green'>
            {classifications[0].type}
          </Badge>
          <Badge variant='outline' colorScheme='green'>
            {classifications[0].country}
          </Badge>
          {classifications[0].zip && (
            <Badge variant='outline' colorScheme='green'>
              {classifications[0].zip}
            </Badge>
          )}
          {classifications.length > 1 && (
            <Tooltip label={classifications.map(value => `${value.type} ${value.zip}`).join(', ')}>
              <Badge variant='solid'>
                +{classifications.length - 1}
              </Badge>
            </Tooltip>
          )}
        </Flex>

  )
}

export default LocationClassificationResultClassification
