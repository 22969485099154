import React, {FunctionComponent} from 'react'
import {Button, Stack} from '@chakra-ui/react'
import {LanguageToolsResult} from '../../../apis/indoqa-demos'

interface Params {
  termParameter: string
  categoryParameter: string
  pageParameter: string
}

interface Props {
  category: string
  onSelect: (facet: string) => any
  result?: LanguageToolsResult
}

const OeamtcGisSearchFacets: FunctionComponent<Props> = ({category, onSelect, result}: Props) => {
  if (!result || !result.found || result.found === 0) {
    return null
  }

  return (
    <Stack align={'flex-end'} w={300}>
      <Button variant={category === 'all' ? 'primary' : 'light'} disabled={category === 'all'} size="sm" className="ml-2" onClick={() => onSelect('all')}>
        All
      </Button>
      {result &&
        result.categories &&
        result.categories
          .sort((a, b) => a.text!.toLowerCase().localeCompare(b.text!.toLowerCase()))
          .map(value => (
            <Button variant={category === value.text ? 'primary' : 'light'} disabled={category === value.text} size="sm" className="ml-2" onClick={() => onSelect(value.text!)}>
              {value.text} {category === 'all' && <span>({value.count})</span>}
            </Button>
          ))}
    </Stack>
  )
}

export default OeamtcGisSearchFacets
