import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {ChatbotsPage} from "./pages/ChatbotsPage";

interface Props {
  isSideBarExpanded: boolean
}
const ChatbotArbeitsrecht = ({isSideBarExpanded}: Props) => {
  return (
    <Routes>
      <Route path={`/`} element={<ChatbotsPage isSideBarExpanded={isSideBarExpanded} />} />
    </Routes>
  )
}

export default ChatbotArbeitsrecht
