import {ChatbotInput} from '../../../apis/indoqa-demos'
import {ChatbotDialogItem} from './ChatbotDialogItem'
import React from 'react'

interface Props {
  input: ChatbotInput
}

export const ChatbotInputPanel = ({input}: Props) => {
  const inputPanel = <div dangerouslySetInnerHTML={{__html: input.text}} />
  return <ChatbotDialogItem left="User" right={inputPanel} />
}
