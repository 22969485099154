export interface Example {
  name: string
  term: string
}

export const examples: Example[] = [
  {
    name: 'Aufzählungen',
    term: 'Mo-Fr 06:00-23:00 Sa, So, Feiertag 08:00-21:00',
  }, {
    name: 'Werktags',
    term: 'Werktags von 10 bis 18'
  },
  {
    name: 'Werktags (mit Schreibfehler)',
    term: `7h-19h Wektags`,
  },
]
