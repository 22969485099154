import React from 'react'
import {Box, Button, Flex} from '@chakra-ui/react'
import TopCard from '../../../components/TopCard'
import {ChatbotInput, PostUserInputToChatbotRequest} from '../../../apis/indoqa-demos'
import {ChatbotDialog} from '../components/ChatbotDialog'
import {ChatbotClient} from '../../../apis'
import {ChatbotInteraction} from '../chatbot.types'
import ReactTextareaAutosize from 'react-textarea-autosize'
import {
  getBottomContainerStyle,
  innerBottomContainerStyle,
  placeholderBoxStyle,
  sendButtonStyle,
  textareaStyle,
} from './ChatbotsPage.styles'
import Editor from '@monaco-editor/react'
import {mergeMap, tap} from 'rxjs'

const getConversationId = (interactions: ChatbotInteraction[]) => {
  return interactions.find(interaction => !!interaction.conversationId)
}

interface Props {
  isSideBarExpanded: boolean
}

export const ChatbotsPage = ({isSideBarExpanded}: Props) => {
  const [interactions, setInteractions] = React.useState<ChatbotInteraction[]>([])
  const [message, setMessage] = React.useState<string>('')
  const [debugInteraction, setDebugInteraction] = React.useState<null | string>(null)
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false)
  const previousInteractionsRef = React.useRef<ChatbotInteraction[]>([])
  const scrollBoxRef = React.useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = scrollBoxRef.current.scrollHeight
    }
  }

  React.useEffect(() => {
    if (previousInteractionsRef.current !== interactions) {
      scrollToBottom()
    }
  }, [previousInteractionsRef, interactions])

  const sendMessage = () => {
    if (message.trim() === '') {
      return
    }
    setSubmitting(true)

    const chatbotInput: ChatbotInput = {
      text: message,
      conversationId: getConversationId(interactions)?.conversationId,
    }
    const messageHtml = message.replace(/<[^>]*>/g, '').replaceAll('\n', '<br/>')
    const cleanedChatbotInput: ChatbotInput = {
      text: messageHtml,
      conversationId: getConversationId(interactions)?.conversationId,
    }
    setMessage('')
    previousInteractionsRef.current = interactions
    setInteractions(interactions => [...interactions, cleanedChatbotInput])

    const request: PostUserInputToChatbotRequest = {
      chatbotInput,
      chatbotName: 'ar',
    }
    ChatbotClient.postUserInputToChatbot(request)
      .pipe(
        tap(answer => {
          previousInteractionsRef.current = interactions
          setInteractions(interactions => [...interactions, answer])
          setSubmitting(false)
        }),
        mergeMap(answer => {
          const {conversationId, interactionId} = answer
          return ChatbotClient.getInteraction({conversationId, interactionId})
        }),
      )
      .subscribe(interaction => {
        setDebugInteraction(JSON.stringify(interaction, null, 2))
      })
  }

  return (
    <div className="chatbot">
      <Flex height="100vh" display="flex" flexDirection="column" overflow="hidden">
        <TopCard title="Chatbots">{}</TopCard>
        <Box flexGrow={1} h="full">
          <Flex w="full" h="full">
            <Flex flexGrow={1} justifyContent="center">
              <Box maxW={1000} w="full" p={6} overflowY={'scroll'} ref={scrollBoxRef}>
                <ChatbotDialog interactions={interactions} isSubmitting={isSubmitting} />
                <Box style={placeholderBoxStyle} />
              </Box>
            </Flex>
            <Box flexGrow={0.66} h="calc(100% - 100px)">
              <Editor
                language="json"
                value={debugInteraction || ''}
                options={{
                  readOnly: true,
                  wordWrap: true,
                  scrollBeyondLastLine: false,
                  scrollbar: {
                    verticalScrollbarSize: 8,
                    horizontalScrollbarSize: 8,
                    horizontal: 'visible',
                    vertical: 'visible',
                  },
                  fontSize: 13,
                  fontFamily: 'Monaco, Menlo, "Ubuntu Mono", Consolas, source-code-pro, monospace',
                  wordBasedSuggestions: false,
                }}
                keepCurrentModel={false}
              />
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Box style={getBottomContainerStyle(isSideBarExpanded)} bg="white">
        <Flex alignItems="center" justifyContent="center">
          <Box style={innerBottomContainerStyle}>
            <ReactTextareaAutosize
              style={textareaStyle}
              minRows={1}
              maxRows={8}
              placeholder="Send a message"
              value={message}
              onKeyDown={e => {
                //if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                if (!isSubmitting && e.key == 'Enter' && !e.shiftKey) {
                  sendMessage()
                }
              }}
              onChange={e => setMessage(e.target.value || '')}
            />
            <Button
              colorScheme={'green'}
              disabled={isSubmitting || message.trim() === ''}
              onClick={() => sendMessage()}
              style={sendButtonStyle}
            >
              Send
            </Button>
          </Box>
        </Flex>
      </Box>
    </div>
  )
}
