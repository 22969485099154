import React, { useEffect, useMemo, useState } from "react";
import { Badge, Box, Flex, HStack, Input, Stack, Text, useDisclosure } from "@chakra-ui/react";
import TopCard from "../../../components/TopCard";
import { BehaviorSubject, debounceTime } from "rxjs";
import {
  LocationAutocompleteResult,
  LocationAutocompleteResultItem,
  LocationAutocompleteScenario
} from "../../../apis/indoqa-demos";
import { CloseIcon } from "@chakra-ui/icons";
import { useCombobox } from "downshift";
import LocationAutocompleteHint from "./LocationAutocompleteHint";

export interface ExampleItemProps {
  onSubmit: (input: string, clearSubmitting: () => void) => void
  onSelect: (item: LocationAutocompleteResultItem) => void

  selectedItem?: LocationAutocompleteResultItem
  deselectItem: (item?: LocationAutocompleteResultItem) => void
  scenarios?: LocationAutocompleteScenario[],
  result?: LocationAutocompleteResult
}

const subject$ = new BehaviorSubject("");

const createInput = (item: LocationAutocompleteResultItem) => {
  if (!item) {
    return "";
  }

  if (!item.id) {
    return item.name!
  }

  const prefix = item.zip ? item.zip + " " : "";

  if (item.name === item.city) {
    return prefix + item.city;
  }

  return prefix + item.city + ", " + item.name;
};

const LocationAutocompleteForm = (
  {
    onSubmit,
    selectedItem,
    deselectItem,
    scenarios,
    result,
    onSelect
  }: ExampleItemProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [value, setValue] = useState("");

  const items = useMemo(() => {
    if (result && result.scenarios) {
      return result.scenarios
        .flatMap(value1 => value1.items)
        .map(value => value as LocationAutocompleteResultItem)
        .reduce((accumulator, current) => {
          if (!accumulator.find((item) => item.name=== current.name
            && item.zip=== current.zip
            && item.city=== current.city
          )) {
            accumulator.push(current);
          }
          return accumulator;
        }, [] as LocationAutocompleteResultItem[]);
    }

    return [];
  }, [scenarios]);

  const stateReducer = React.useCallback((state: any, actionAndChanges: any) => {
    const { type, changes } = actionAndChanges;


    switch (type) {
      case useCombobox.stateChangeTypes.FunctionSelectItem:
      case useCombobox.stateChangeTypes.InputChange:
        return {
          ...actionAndChanges.changes,
          userInput: actionAndChanges.changes.inputValue
        };
      case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
      case useCombobox.stateChangeTypes.InputKeyDownArrowDown:
        return {
          ...changes,
          userInput: actionAndChanges.changes.inputValue,
          inputValue: items[changes.highlightedIndex] ? createInput(items[changes.highlightedIndex]!) : changes.inputValue!
        };
      case useCombobox.stateChangeTypes.FunctionReset:
        return {
          ...actionAndChanges.changes,
          inputValue: "esc"
        };
      default:
        return changes;
    }
  }, [items]);


  const {
    isOpen: isDownshiftOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem: selectedDownshiftItem
  } = useCombobox({
    stateReducer,
    onHighlightedIndexChange: ({ inputValue, highlightedIndex, selectedItem }) => {
      console.log(inputValue, highlightedIndex, highlightedIndex && highlightedIndex > -1 && items[highlightedIndex]);
    },
    onInputValueChange: ({ userInput, inputValue }: any) => {
      if (userInput === inputValue) {
        onSubmit(userInput, () => {
        });
      }
    },
    items,
    itemToString(item) {
      return createInput(item!);
    },
    onSelectedItemChange: ({ selectedItem, userInput }: any) => {
      console.log("onselect", selectedItem);
      onSelect(selectedItem!);
      onSubmit(userInput, () => {
      });
    }
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    subject$.next(e.target.value);
    setValue(e.target.value);
  };

  useEffect(() => {
    const subscription = subject$
      .pipe(debounceTime(200))
      .subscribe(value => {
        onSubmit(value, () => {
        });
      });

    return () => subscription.unsubscribe();
  }, [onSubmit]);

  useEffect(() => {
    if (selectedItem) {
      setValue("");
      onSubmit("", () => {
      });
    }
  }, [selectedItem]);


  return (
    <Box position={"absolute"} zIndex={1000} backgroundColor={"white"}>
      <TopCard
        title={"Location Search Autocomplete"}
      >
        <Stack direction={{ base: "column", md: "row" }}>
          {selectedItem && (
            <Flex alignItems={"center"}>
              <Badge variant="subtle" p={2} css={"cursor: pointer;"} key={selectedItem.id}
                     onClick={() => deselectItem(undefined)}>
                <Flex alignItems={"center"}>{selectedItem.name} {selectedItem.zip} <CloseIcon ml={1} h={2} /></Flex>
              </Badge>
            </Flex>
          )}
          <Input className="form-control" width={400} type="text"
                 placeholder={"Enter search term"}  {...getInputProps()} />
          <button
            aria-label="toggle menu"
            className="px-2"
            type="button"
            {...getToggleButtonProps()}
          >
            {isOpen ? <>&#8593;</> : <>&#8595;</>}
          </button>
        </Stack>
        <Box
          hidden={!(isDownshiftOpen && items.length)}
          position={"absolute"}
          boxShadow="lg" borderRadius="sm"
          backgroundColor={"white"}
          zIndex={100}
          w={400}
          mt={2}
          {...getMenuProps()}
        >
          {isDownshiftOpen && (
            <Stack>
              <Stack height={400}
                     overflowY={"scroll"}>
                {items.map((item, index) => (
                  <HStack
                    backgroundColor={highlightedIndex === index ? "blue.300" : "transparent"}
                    fontWeight={selectedDownshiftItem === item ? "bold" : "normal"}
                    px={4}
                    py={2}
                    borderBottomColor={"gray.50"}
                    borderBottomStyle={"solid"}
                    borderBottomWidth={1}
                    justifyContent={"space-between"}
                    key={`${item!.id!}${index}`}
                    width={"full"}
                    {...getItemProps({ item, index })}>
                    <Text fontSize={"sm"} noOfLines={1}>
                      <Text fontWeight={"500"} display={"inline"}> {item!.name} </Text>
                      <Text fontWeight={"300"} display={"inline"} color={"gray.500"}> {item!.zip} </Text>
                      <Text fontWeight={"300"} display={"inline"} color={"gray.500"}> {item!.city} </Text>
                    </Text>
                    <Box>
                      <Badge opacity={0.4}> {item!.type && item!.type.substring(0, 1)} </Badge>
                    </Box>
                  </HStack>
                ))}
              </Stack>
              <LocationAutocompleteHint result={result} />
            </Stack>
          )}
        </Box>
      </TopCard>
    </Box>
  );
};

export default LocationAutocompleteForm;
