import React, {FunctionComponent} from 'react'
import {Route, Routes} from 'react-router-dom'

import OpeningHoursPage from './pages/OpeningHoursPage'

const OpeningHoursModule: FunctionComponent = () => {
  return (
    <Routes>
      <Route path={`/`} element={<OpeningHoursPage />} />
    </Routes>
  )
}

export default OpeningHoursModule
