import {ChatbotAnswer} from '../../../apis/indoqa-demos'
import {Box} from '@chakra-ui/react'
import {ChatbotDialogItem} from './ChatbotDialogItem'
import React from 'react'

interface Props {
  answer: ChatbotAnswer
}

export const ChatbotAnswerPanel = ({answer}: Props) => {
  const answerPanel = <div dangerouslySetInnerHTML={{__html: answer.text}} />
  return <ChatbotDialogItem left="Bot" right={answerPanel} />
}
