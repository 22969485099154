import * as React from 'react'
import {TextareaStyle} from '../chatbot.types'
import {SIDEBAR_WIDTH_COLLAPSED, SIDEBAR_WIDTH_EXPANDED} from '../../../components/Sidebar.const'
import {TEXT_CONTAINER_WIDTH} from './ChatBotsPage.const'

export const getBottomContainerStyle = (isSideBarExpanded: boolean): React.CSSProperties => {
  const sideBarWidth = isSideBarExpanded ? SIDEBAR_WIDTH_EXPANDED : SIDEBAR_WIDTH_COLLAPSED
  return {
    position: 'absolute',
    bottom: '0px',
    width: `calc(100vw - ${sideBarWidth})`,
  }
}

export const innerBottomContainerStyle: React.CSSProperties = {
  position: 'relative',
  background: 'white',
  paddingTop: '10px',
  paddingBottom: '30px',
  //height: '70px'
}

export const placeholderBoxStyle: React.CSSProperties = {
  height: '110px',
}

export const textareaStyle: TextareaStyle = {
  width: TEXT_CONTAINER_WIDTH,
  borderWidth: '1px',
  borderColor: 'inherit',
  borderRadius: '5px',
  resize: 'none',
  paddingLeft: '12px',
  paddingRight: '12px',
  paddingTop: '6px',
  paddingBottom: '6px',
  outlineColor: 'var(--chakra-ring-color)',
  marginRight: '12px',
}

export const sendButtonStyle: React.CSSProperties = {
  position: 'absolute',
  bottom: '34px',
}
