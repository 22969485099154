export interface Example {
  name: string
  term: string
}

export const examples: Example[] = [
  {
    name: '1130 Wien, Burggasse 2',
    term: '1130 Wien, Burggasse 2',
  },
  {
    name: 'via sacra florenz',
    term: 'via sacra florenz'
  },
  {
    name: 'via sacra florenz',
    term: 'via sacra florenz'
  }
]
