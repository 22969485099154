import React, {FunctionComponent, useCallback, useState} from 'react'
import {
  Box,
  Button, Code, Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Stack, Tab, TabList, TabPanel, TabPanels, Tabs,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import {Field, Form, Formik} from 'formik'
import {HamburgerIcon} from '@chakra-ui/icons'
import ExampleItem from '../../location/components/ExampleItem'
import TopCard from '../../../components/TopCard'
import {OpeningHourEntry, OpeningHoursParseResult} from '../../../apis/indoqa-demos'
import {OpeningHoursClient} from '../../../apis'
import Card from '../../../components/Card'
import {examples} from '../examples'

interface TermCategory {
  category: string
  properties: string[]
}

interface Term {
  term: string
  categories: TermCategory[]
}

const colorChart = (day: string, weekday: boolean, from: string, to: string, result: OpeningHoursParseResult): any => {
  const match: OpeningHourEntry | undefined = result.entries!.find(value => {
    if (!(value.day == null) && (value.day && value.day !== day && !(value.day === 'WORKDAY' && weekday))) {
      return false
    }

    const startCompare = parseInt(from!.split(':')[0])
    const endCompare = parseInt(to!.split(':')[0])

    const start = parseInt(value.from!.split(':')[0])
    const end = parseInt(value.to!.split(':')[0])

    const startMinutes = parseInt(value.from!.split(':')[1])
    const endMinutes = parseInt(value.to!.split(':')[2])

    const endDelta = endMinutes > 0 ? 0 : 1
    const startDelta = startMinutes > 0 ? 0 : 1

    if (start > endCompare - startDelta) {
      return false
    }

    if (end < startCompare + endDelta) {
      return false
    }

    return true
  })

  return match ? 'green.300' : 'gray.100'
}

const OpeningHoursPage: FunctionComponent = () => {
  const [result, setResult] = useState<OpeningHoursParseResult | undefined>(undefined)
  const {isOpen, onOpen, onClose} = useDisclosure()

  const submit = useCallback(({term}: any, {setSubmitting}: any) => {
    console.log(term)
    setSubmitting(false)

    OpeningHoursClient.parseOpeningHours({
      openingHoursParseInput: {
        input: term,
      },
    }).subscribe(setResult)
  }, [])

  const createHour = (index: number) => (
    <Stack>
      <Box w={9} h={5} bg={'gray.100'} fontFamily={'heading'}>
        {index}:00
      </Box>
      <Box w={9} h={5} bg={colorChart('MONDAY', true, `${index}:00`, `${index + 1}:00`, result!)} />
      <Box w={9} h={5} bg={colorChart('TUESDAY', true, `${index}:00`, `${index + 1}:00`, result!)} />
      <Box w={9} h={5} bg={colorChart('WEDNESDAY', true, `${index}:00`, `${index + 1}:00`, result!)} />
      <Box w={9} h={5} bg={colorChart('THURSDAY', true, `${index}:00`, `${index + 1}:00`, result!)} />
      <Box w={9} h={5} bg={colorChart('FRIDAY', true, `${index}:00`, `${index + 1}:00`, result!)} />
      <Box w={9} h={5} bg={colorChart('SATURDAY', false, `${index}:00`, `${index + 1}:00`, result!)} />
      <Box w={9} h={5} bg={colorChart('SUNDAY', false, `${index}:00`, `${index + 1}:00`, result!)} />
      <Box w={9} h={5} bg={colorChart('HOLIDAY', false, `${index}:00`, `${index + 1}:00`, result!)} />
    </Stack>
  )

  return (
    <>
      <Formik initialValues={{term: ''}} onSubmit={submit}>
        {({isSubmitting, setFieldValue, submitForm, values}) => (
          <Form>
            <TopCard
              title={'Opening Hours Text Input Analyzer'}
              menu={
                <Button aria-label={'Examples'} variant='ghost' size={'sm'} onClick={onOpen}>
                  Examples
                </Button>
              }
            >
              <Stack direction={{base: 'column', md: 'row'}} justifyContent='space-between'>
                <Field name='term' as={Textarea} className='form-control' type='text' placeholder={'Enter text'}
                       rows={10} />
                <Stack direction={{base: 'column', md: 'row'}}>
                  <Button type='submit' colorScheme={'green'} disabled={isSubmitting}>
                    Analyze
                  </Button>
                </Stack>
              </Stack>
            </TopCard>
            <Drawer isOpen={isOpen} placement='right' onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Examples</DrawerHeader>

                <DrawerBody>
                  {examples.map(example => (
                    <ExampleItem onSelect={(value) => {
                      setFieldValue('term', value)
                      onClose()
                      submitForm()
                    }} example={example} key={example.term} />
                  ))}
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Form>
        )}
      </Formik>
      {result && (
        <Box m={6}>
          <Tabs>
            <TabList>
              <Tab>JSON Result</Tab>
              <Tab>Chart</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>

              <pre style={{fontSize: 12}}>
                {JSON.stringify(result, null, 4)}
              </pre>


              </TabPanel>
              <TabPanel>
                <Stack mt={12}>
                  <HStack fontSize={'xs'} spacing='1px'>
                    <Stack fontFamily={'heading'}>
                      <Box w={30} h={5}>
                        Day
                      </Box>
                      <Box w={30} h={5}>
                        Mo
                      </Box>
                      <Box w={30} h={5}>
                        Tu
                      </Box>
                      <Box w={30} h={5}>
                        We
                      </Box>
                      <Box w={30} h={5}>
                        Th
                      </Box>
                      <Box w={30} h={5}>
                        Fr
                      </Box>
                      <Box w={30} h={5}>
                        Sa
                      </Box>
                      <Box w={30} h={5}>
                        So
                      </Box>
                      <Box w={30} h={5}>
                        Ho
                      </Box>
                    </Stack>
                    {createHour(0)}
                    {createHour(1)}
                    {createHour(2)}
                    {createHour(3)}
                    {createHour(4)}
                    {createHour(5)}
                    {createHour(6)}
                    {createHour(7)}
                    {createHour(8)}
                    {createHour(9)}
                    {createHour(10)}
                    {createHour(11)}
                    {createHour(12)}
                    {createHour(13)}
                    {createHour(14)}
                    {createHour(15)}
                    {createHour(16)}
                    {createHour(17)}
                    {createHour(18)}
                    {createHour(19)}
                    {createHour(20)}
                    {createHour(21)}
                    {createHour(22)}
                    {createHour(23)}
                  </HStack>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </>
  )
}

export default OpeningHoursPage
