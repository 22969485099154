import {Authenticator} from '../auth/Authenticator'
import {KeycloakAuthenticator} from '../auth/KeycloakAuthenticator'

const GROUP_SEARCH_WORKBENCH = '/apps/indoqa-demos'

const getInstance = (): Authenticator => {
  return new KeycloakAuthenticator(GROUP_SEARCH_WORKBENCH)
}

export const authenticator: Authenticator = getInstance()
