import React, { useEffect, useRef, useState } from "react";
import Map, {
  FullscreenControl,
  GeolocateControl,
  MapRef,
  Marker,
  NavigationControl,
  ScaleControl
} from "react-map-gl";
import maplibreGl from 'maplibre-gl'
import { Coordinate, LocationAutocompleteResultItem } from "../../../apis/indoqa-demos";
import basicMapStyles from './mapbox-basic-layers.json'
import { Box, Tooltip } from "@chakra-ui/react";
import Pin from "./Pin";

interface Props {
  coordinate?: Coordinate,
  pin?: LocationAutocompleteResultItem,
  setCoordinate: (coordinate: Coordinate) => void,
  onBoundingBoxUpdate: (minLongitude: number, minLatitude: number, maxLongitude: number, maxLatitude: number) => void

}

export default function MapSelector({coordinate, onBoundingBoxUpdate, setCoordinate, pin}: Props) {
  const mapRef = useRef<MapRef>(null)
  const [zoom] = useState(5)

  useEffect(() => {
    if (!pin) {
      return
    }
    mapRef.current!.flyTo({zoom: 18, center: {lng: pin.lon!, lat: pin.lat!}})
  }, [pin, zoom, mapRef])

  if (!coordinate) {
    return null
  }
  console.log('pin', pin)

  return (
    <Map
      mapLib={maplibreGl}
      attributionControl={false}
      initialViewState={{
        longitude: 19,
        latitude: 48,
        zoom: 5
      }}
      style={{
        position: 'fixed',
        top: 0,
        left: 190,
        right: 0,
        bottom: 0,

      }}
      mapboxAccessToken="pk.eyJ1IjoibWVwaGVzZXIiLCJhIjoiY2xrOXM4dDVhMDBmcTNrbXQ5b2lrZnZldiJ9.ZOvah6bvS6E0oz81mvW6kg"
      mapStyle='https://api.maptiler.com/maps/basic-v2/style.json?key=iECT3tKy8r5Cf6UKgV9W'
      ref={mapRef}
      onMoveEnd={(event) => {
        const bounds = event.target.getBounds()
        setCoordinate({latitude: event.target.getCenter().lat, longitude: event.target.getCenter().lng})
        onBoundingBoxUpdate(bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth())
      }}
    >
      <GeolocateControl position="bottom-right" />
      <FullscreenControl position="bottom-right" />
      <NavigationControl position="bottom-right" />
      <ScaleControl />
      {pin && (
        <Marker longitude={pin.lon} latitude={pin.lat} key={'pi'}>
            <Pin tooltip={pin.name} />
        </Marker>
      )}
        <NavigationControl position={'bottom-right'} showZoom={true}/>
    </Map>
  )
}