import {ReactNode} from 'react'
import {Box, Flex} from '@chakra-ui/react'

interface Props {
  left: ReactNode
  right: ReactNode
}

export const ChatbotDialogItem = ({left, right}: Props) => {
  return (
    <Flex py={1}>
      <Box pr={6} w={20} flexGrow={0} flexShrink={0}>
        <b>{left}</b>
      </Box>
      <Box flexGrow={1}>{right}</Box>
    </Flex>
  )
}
