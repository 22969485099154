import React, {FunctionComponent} from 'react'
import {Route, Routes} from 'react-router-dom'
import AdminHomePage from './pages/AdminHomePage'
import AdminAccountPage from './pages/AdminAccountPage'

const AdminModule: FunctionComponent = () => {
  return (
    <Routes>
      <Route path={`/home`} element={<AdminHomePage />} />
      <Route path={`/account`} element={<AdminAccountPage />} />
    </Routes>
  )
}

export default AdminModule
