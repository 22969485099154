import React, {FunctionComponent} from 'react'
import {Box, Flex, Heading, Stack} from '@chakra-ui/react'

interface Props {
  children: React.ReactNode
}

const Card: FunctionComponent<Props> = ({children}) => (
  <Stack boxShadow="lg" borderRadius="sm">
    {children && (
      <Box p={4}>
        {children}
      </Box>
    )}
  </Stack>
)

export default Card
