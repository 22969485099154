import {Box, Flex} from '@chakra-ui/react'
import {ChatbotInteraction} from '../chatbot.types'
import {ChatbotAnswer} from '../../../apis/indoqa-demos'
import {ChatbotAnswerPanel} from './ChatbotAnswerPanel'
import {ChatbotInputPanel} from './ChatbotInputPanel'
import {ChatbotDialogItem} from './ChatbotDialogItem'
import { TEXT_CONTAINER_WIDTH } from '../pages/ChatBotsPage.const'

interface Props {
  interactions: ChatbotInteraction[]
  isSubmitting: boolean
}

const isChatbotAnswer = (interaction: ChatbotInteraction): interaction is ChatbotAnswer => {
  return (interaction as ChatbotAnswer).interactionId !== undefined
}

export const ChatbotDialog = ({interactions, isSubmitting}: Props) => {
  return (
    <Flex justifyContent='center'>
      <Box width={TEXT_CONTAINER_WIDTH}>
        {interactions.map((interaction, pos) => {
          if (isChatbotAnswer(interaction)) {
            return <ChatbotAnswerPanel key={pos} answer={interaction} />
          }
          return <ChatbotInputPanel key={pos} input={interaction} />
        })}
        {isSubmitting && <ChatbotDialogItem left="Bot" right={<i>answering ...</i>} />}
      </Box>
    </Flex>    
  )
}
