import React, {FunctionComponent} from 'react'
import {Box, Button} from '@chakra-ui/react'

const PAGE_SIZE = 10

interface Props {
  currentPage: number
  found: number
  onSelect: (page: number) => any
}

const Paging: FunctionComponent<Props> = ({currentPage, found, onSelect}: Props) => {
  const pages = []

  while (pages.length * PAGE_SIZE < found) {
    pages.push(pages.length + 1)
  }

  return (
    <Box>
      <Button onClick={() => onSelect(1)} disabled={currentPage < 2} />
      <Button onClick={() => onSelect(currentPage - 1)} disabled={currentPage < 2} />
      {pages.map(eachPage => (
        <Button key={eachPage} onClick={() => onSelect(eachPage)}>
          {eachPage}
        </Button>
      ))}
      <Button disabled={currentPage > pages.length - 1} onClick={() => onSelect(currentPage + 1)} />
      <Button disabled={currentPage > pages.length - 1} onClick={() => onSelect(pages.length)} />
    </Box>
  )
}

export default Paging
