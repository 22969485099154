import React, {FunctionComponent, useCallback} from 'react'
import {
  Box,
  Button,
  Drawer, DrawerBody,
  DrawerCloseButton,
  DrawerContent, DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  MenuItem,
  Stack, useDisclosure,
} from '@chakra-ui/react'
import {Example, examples} from '../examples'
import {Field, Form, Formik} from 'formik'
import TopCard from '../../../components/TopCard'
import ExampleItem from './ExampleItem'

export interface ExampleItemProps {
  onSubmit: (input: string, clearSubmitting: () => void) => void
}

const LocationClassificationForm = ({onSubmit}: ExampleItemProps) => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  const submit = useCallback(({input}: any, {setSubmitting}: any) => {
    onSubmit(input, () => setSubmitting(false))
  }, [])



  return (
    <Formik initialValues={{input: ''}} onSubmit={submit}>
      {({isSubmitting, setFieldValue, submitForm}) => (
        <Form>
          <TopCard
            title={'Location Query Input Classifications'}
            menu={
              <HStack>
                <Button aria-label={'Examples'} variant='ghost' size={'sm'} onClick={onOpen}>
                  Examples
                </Button>
                <Button aria-label={'Examples'} variant='ghost' size={'sm'} onClick={onOpen}>
                  Help
                </Button>
              </HStack>
            }
          >
            <Stack direction={{base: 'column', md: 'row'}} justifyContent='space-between'>
              <Field name='input' className='form-control' type='text' placeholder={'Enter search term'}>
                {({ field, form }: any) => (
                  <Input
                    {...field}
                  />
                )}
              </Field>
              <Stack direction={{base: 'column', md: 'row'}}>
                <Button type='submit' colorScheme={'green'} disabled={isSubmitting}>
                  Analyze
                </Button>
              </Stack>
            </Stack>
          </TopCard>
          <Drawer isOpen={isOpen} placement='right' onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Examples</DrawerHeader>

              <DrawerBody>
                {examples.map(example => (
                  <ExampleItem onSelect={(value) => {
                    setFieldValue('input', value);
                    onClose();
                    submitForm();
                  }} example={example} key={example.term} />
                ))}
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Form>
      )}
    </Formik>
  )
}

export default LocationClassificationForm
