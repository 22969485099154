import KeycloakAdapter from 'keycloak-js'

export interface AuthenticatedUser {
  id: string
  username: string
  name: string
  email: string
}

export interface Authenticator {
  init: (done: (authenticated: boolean) => void) => void
  isInitialized: () => boolean
  getToken: () => string | undefined
  getTokenType: () => string | undefined
  hasGroup: (expectedGroup: string) => boolean
  hasRole: (expectedRole: string) => boolean
  isAuthenticated: () => boolean
  hasPermission: (permission: Permission) => boolean
  login: () => void
  logout: () => void
  currentUser: () => AuthenticatedUser | undefined
}

export enum Permission {
  LOCATION_MENU_ITEM_SNOW = 'LOCATION_MENU_ITEM_SNOW',
  OPENING_HOURS_MENU_ITEM_SNOW = 'OPENING_HOURS_MENU_ITEM_SNOW',
  LANGUAGE_TOOLS_MENU_ITEM_SNOW = 'LANGUAGE_TOOLS_MENU_ITEM_SNOW',
  CHATBOT_MENU_ITEM_SNOW = 'CHATBOT_MENU_ITEM_SNOW',
}
