import React, {FunctionComponent, useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {getSessionId, getTabId} from '../../../analytics/analytics'
import {useNavigate} from 'react-router-dom'
import {Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Container, Heading, Stack} from '@chakra-ui/react'
import {GetDetailRequest, LanguageToolsResultItem} from '../../../apis/indoqa-demos'
import {LanguageToolsApiClient} from '../../../apis'

const LanguageToolsDetailPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const {idParameter, searchIdParameter} = useParams()
  const [detail, setDetail] = useState<LanguageToolsResultItem | null>(null)

  useEffect(() => {
    const request: GetDetailRequest = {
      id: idParameter!,
      instance: 'oeamtc-gis',
    }

    LanguageToolsApiClient.getDetail(request).subscribe(setDetail)

    navigate(`/language-tools/detail/${idParameter}`, {replace: true})

    window.scrollTo(0, 0)
  }, [idParameter])

  useEffect(() => {
    window.document.title = `${detail?.title ? detail?.title : 'Lade...'} | ÖAMTC GIS Search Demo`
  }, [detail])

  if (!detail) {
    return <> </>
  }

  return (
    <>
      <Container maxW={'container.lg'} pt={12}>
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink href="#">Language Tools Search Demo</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Detail</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading fontSize={'3xl'} mb={4}>
          {' '}
          {detail.title}{' '}
        </Heading>
        <Stack spacing={'10px'}>
          {detail.texts!.map(value => (
            <Box dangerouslySetInnerHTML={{__html: value}} />
          ))}
        </Stack>
      </Container>
    </>
  )
}

export default LanguageToolsDetailPage
