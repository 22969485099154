import {ColorModeScript} from '@chakra-ui/react'
import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import App from './app/App'
import {authenticator} from './app/authenticator'
import './global.css'

const container = document.getElementById('root')
if (!container) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(container)

authenticator.init(authenticated => {
  if (authenticated) {
    root.render(
      <React.StrictMode>
        <ColorModeScript />
        <App />
      </React.StrictMode>,
    )
  } else {
    authenticator.login()
  }
})
