import React, {FunctionComponent} from 'react'
import Paging from '../../../components/Paging'
import {LanguageToolsResult} from '../../../apis/indoqa-demos'

interface Params {
  termParameter: string
  categoryParameter: string
  pageParameter: string
}

interface Props {
  onPageChange: (newPage: number) => any
  currentPage: string
  result?: LanguageToolsResult
}

const OeamtcGisSearchPaging: FunctionComponent<Props> = ({onPageChange, currentPage, result}: Props) => {
  return <>{result && result.found && result.found > 0 && <Paging onSelect={onPageChange} currentPage={currentPage ? parseInt(currentPage) : 1} found={result.found!} />}</>
}

export default OeamtcGisSearchPaging
