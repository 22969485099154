import React, {FunctionComponent} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import LanguageToolsSearchPage from './pages/LanguageToolsSearchPage'
import LanguageToolsDetailPage from './pages/LanguageToolsDetailPage'

const LanguageToolsModule: FunctionComponent = () => {
  return (
    <Routes>
      <Route path={`/search-detail/:searchIdParameter/:idParameter`} element={<LanguageToolsDetailPage />} />
      <Route path={`/detail/:idParameter`} element={<LanguageToolsDetailPage />} />
      <Route path={`/search/:searchModeParameter/:categoryParameter/:pageParameter/:termParameter`} element={<LanguageToolsSearchPage />} />
      <Route path={`/search/:searchModeParameter/:categoryParameter/:pageParameter`} element={<LanguageToolsSearchPage />} />
      <Route path="/search" element={<Navigate to="/language-tools/search/BASIC/all/0" replace />} />
      <Route path="*" element={<Navigate to="/language-tools/search" replace />} />
    </Routes>
  )
}

export default LanguageToolsModule
