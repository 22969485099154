import {Global} from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      /* latin */
       @font-face {
        font-family: 'Graphik';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('/fonts/Graphik-Medium-Web.woff2') format('woff2');
      }
       @font-face {
        font-family: 'Graphik';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/Graphik-Regular-Web.woff2') format('woff2');
      }
       @font-face {
        font-family: 'Graphik';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('/fonts/Graphik-Light-Web.woff2') format('woff2');
      }
       @font-face {
        font-family: 'Cooper';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/fonts/CooperBTW03-Light.woff2') format('woff2');
      }
      
      .highlight {
        background-color: rgba(34,139,34, 0.2)
      }
     
      `}
  />
)

export default Fonts
