import {Box, ChakraProvider, Flex} from '@chakra-ui/react'
import React, {FunctionComponent} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import LanguageToolsModule from '../modules/languagetools/LanguageToolsModule'
import LocationModule from '../modules/location/LocationModule'
import OpeningHoursModule from '../modules/openinghours/OpeningHoursModule'
import Fonts from './Fonts'
import theme from './theme'
import Sidebar from '../components/Sidebar'
import AdminModule from '../modules/admin/AdminModule'
import ChatbotArbeitsrechtModule from "../modules/chatbots/ChatbotsModule";


const App: FunctionComponent = () => {
  const [isSideBarExpanded, setSideBarExpanded] = React.useState<boolean>(true)
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <BrowserRouter>
        <Flex w={'full'}>
          <Sidebar isExpanded={isSideBarExpanded} setExpanded={(isExpanded) => setSideBarExpanded(isExpanded)}/>
          <Box flexGrow={1}>
              <Routes>
                <Route path='/language-tools/*' element={<LanguageToolsModule />} />
                <Route path='/location/*' element={<LocationModule />} />
                <Route path='/opening-hours/*' element={<OpeningHoursModule />} />
                <Route path='/admin/*' element={<AdminModule />} />
                <Route path="/chatbots/*" element={<ChatbotArbeitsrechtModule isSideBarExpanded={isSideBarExpanded} />} />
                <Route path="*" element={<Navigate to="/admin/home" replace />} />
              </Routes>
          </Box>
        </Flex>
      </BrowserRouter>
    </ChakraProvider>
  )
}

export default App
