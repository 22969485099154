// tslint:disable
/**
 * IndoqaDemosAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    LocationAutocompleteInput,
    LocationAutocompleteResult,
    LocationAutocompleteResultItem,
    LocationClassificationInput,
    LocationClassificationResult,
} from '../models';

export interface AutocompleteLocationRequest {
    sessionId?: string;
    tabId?: string;
    locationAutocompleteInput?: LocationAutocompleteInput;
}

export interface ClassifyRequest {
    locationClassificationInput?: LocationClassificationInput;
}

export interface GeocodeLocationRequest {
    id?: string;
    name?: string;
    city?: string;
    zip?: string;
    type?: string;
    sessionId?: string;
    tabId?: string;
}

/**
 * no description
 */
export class LocationApi extends BaseAPI {

    /**
     * Create location autocompletes
     */
    autocompleteLocation({ sessionId, tabId, locationAutocompleteInput }: AutocompleteLocationRequest): Observable<LocationAutocompleteResult>
    autocompleteLocation({ sessionId, tabId, locationAutocompleteInput }: AutocompleteLocationRequest, opts?: OperationOpts): Observable<AjaxResponse<LocationAutocompleteResult>>
    autocompleteLocation({ sessionId, tabId, locationAutocompleteInput }: AutocompleteLocationRequest, opts?: OperationOpts): Observable<LocationAutocompleteResult | AjaxResponse<LocationAutocompleteResult>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = {};

        if (sessionId != null) { query['sessionId'] = sessionId; }
        if (tabId != null) { query['tabId'] = tabId; }

        return this.request<LocationAutocompleteResult>({
            url: '/locations/autocomplete',
            method: 'POST',
            headers,
            query,
            body: locationAutocompleteInput,
        }, opts?.responseOpts);
    };

    /**
     * Classify location query input
     */
    classify({ locationClassificationInput }: ClassifyRequest): Observable<LocationClassificationResult>
    classify({ locationClassificationInput }: ClassifyRequest, opts?: OperationOpts): Observable<AjaxResponse<LocationClassificationResult>>
    classify({ locationClassificationInput }: ClassifyRequest, opts?: OperationOpts): Observable<LocationClassificationResult | AjaxResponse<LocationClassificationResult>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<LocationClassificationResult>({
            url: '/locations/classifications',
            method: 'POST',
            headers,
            body: locationClassificationInput,
        }, opts?.responseOpts);
    };

    /**
     * Geocode an autocomplete result
     */
    geocodeLocation({ id, name, city, zip, type, sessionId, tabId }: GeocodeLocationRequest): Observable<Array<LocationAutocompleteResultItem>>
    geocodeLocation({ id, name, city, zip, type, sessionId, tabId }: GeocodeLocationRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<LocationAutocompleteResultItem>>>
    geocodeLocation({ id, name, city, zip, type, sessionId, tabId }: GeocodeLocationRequest, opts?: OperationOpts): Observable<Array<LocationAutocompleteResultItem> | AjaxResponse<Array<LocationAutocompleteResultItem>>> {

        const query: HttpQuery = {};

        if (id != null) { query['id'] = id; }
        if (name != null) { query['name'] = name; }
        if (city != null) { query['city'] = city; }
        if (zip != null) { query['zip'] = zip; }
        if (type != null) { query['type'] = type; }
        if (sessionId != null) { query['sessionId'] = sessionId; }
        if (tabId != null) { query['tabId'] = tabId; }

        return this.request<Array<LocationAutocompleteResultItem>>({
            url: '/locations/geocode',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

}
