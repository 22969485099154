import React, {FunctionComponent, useCallback} from 'react'
import {
  Badge,
  Box,
  Button,
  Drawer, DrawerBody,
  DrawerCloseButton,
  DrawerContent, DrawerHeader,
  DrawerOverlay, Flex, Heading,
  HStack,
  Input,
  MenuItem,
  Stack, StackDivider, Text, useDisclosure,
} from '@chakra-ui/react'
import {Example, examples} from '../examples'
import {Field, Form, Formik} from 'formik'
import TopCard from '../../../components/TopCard'
import ExampleItem from './ExampleItem'
import {LocationClassificationResult, LocationClassificationTerm} from '../../../apis/indoqa-demos'
import LocationClassificationResultClassification from './LocationClassificationResultClassification'

interface Props {
  title: string,
  terms?: LocationClassificationTerm[]
}

const LocationClassificationResultTerms = ({title, terms}: Props) => {
  if (!terms) {
    return null
  }

  return (
    <>
      <Box ml={4} mr={8} mt={8}>
        <Heading textTransform={'uppercase'} fontSize={'md'} mb={4}>
          {title}
        </Heading>
      </Box>
      <HStack mx={2} divider={<StackDivider borderColor='black.700' borderStyle={'dotted'} />}>
        {terms && terms.map(value => (
          <Box px='4' mx='2' borderRadius='sm' rounded={'sm'} key={value.term}>
            <Text fontWeight={'500'}> {value.term} </Text>
            <Flex mt={1}>
              <LocationClassificationResultClassification classifications={value.classifications} />
            </Flex>
          </Box>
        ))}
      </HStack>
    </>
  )
}

export default LocationClassificationResultTerms
