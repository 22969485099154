// tslint:disable
/**
 * IndoqaDemosAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI } from '../runtime';
import type { OperationOpts, HttpHeaders } from '../runtime';
import type {
    OpeningHoursParseInput,
    OpeningHoursParseResult,
} from '../models';

export interface ParseOpeningHoursRequest {
    openingHoursParseInput?: OpeningHoursParseInput;
}

/**
 * no description
 */
export class OpeningHoursApi extends BaseAPI {

    /**
     * Parse opening hours description
     */
    parseOpeningHours({ openingHoursParseInput }: ParseOpeningHoursRequest): Observable<OpeningHoursParseResult>
    parseOpeningHours({ openingHoursParseInput }: ParseOpeningHoursRequest, opts?: OperationOpts): Observable<AjaxResponse<OpeningHoursParseResult>>
    parseOpeningHours({ openingHoursParseInput }: ParseOpeningHoursRequest, opts?: OperationOpts): Observable<OpeningHoursParseResult | AjaxResponse<OpeningHoursParseResult>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<OpeningHoursParseResult>({
            url: '/opening-hours/parse',
            method: 'POST',
            headers,
            body: openingHoursParseInput,
        }, opts?.responseOpts);
    };

}
