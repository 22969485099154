import React, {FunctionComponent} from 'react'
import {Link} from 'react-router-dom'
import {Box, Flex, Stack, StackDivider} from '@chakra-ui/react'
import {LanguageToolsResult} from '../../../apis/indoqa-demos'

interface Props {
  result?: LanguageToolsResult
}

const OeamtcGisSearchResults: FunctionComponent<Props> = ({result}: Props) => (
  <Stack mt={8} mx={4} divider={<StackDivider borderColor="black.700" borderStyle={'dotted'} />}>
    {result &&
      result.results &&
      result.results.map(resultItem => (
        <Link to={`/language-tools/search-detail/id/${resultItem.id}`}>
          <Box>
            <Box fontSize={'xs'} color={'gray.600'}>
              {resultItem.id}
            </Box>
            <Box fontWeight={'bold'}>
              {resultItem.titleHighlight && <div dangerouslySetInnerHTML={{__html: resultItem.titleHighlight}} />}
              {!resultItem.titleHighlight && <span> {resultItem.title} </span>}
            </Box>
            <Box fontSize={'sm'}>{resultItem.highlights && resultItem.highlights.map(value => <span dangerouslySetInnerHTML={{__html: value}} />)}</Box>
          </Box>
        </Link>
      ))}
  </Stack>
)

export default OeamtcGisSearchResults
