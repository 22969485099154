import React, {FunctionComponent, ReactText, useState} from 'react'
import {Box, Flex, FlexProps, Icon, Image, Text} from '@chakra-ui/react'
import logoInverted from '../app/logo-inverted.png'
import {authenticator} from '../app/authenticator'
import {IconType} from 'react-icons'
import {NavLink} from 'react-router-dom'
import {Permission} from '../auth/Authenticator'
import {
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  FiHome,
  FiUser,
  HiOutlineLocationMarker,
  HiCalendar,
  AiOutlineFileSearch,
  BiConversation
} from 'react-icons/all'
import { SIDEBAR_WIDTH_COLLAPSED, SIDEBAR_WIDTH_EXPANDED } from './Sidebar.const'

interface Props {
  isExpanded: boolean,
  setExpanded: (isExpanded: boolean) => void
}

interface LinkItemProps {
  name: string
  icon: IconType
  to: string
  permission: Permission
}

export const LinkItems: Array<LinkItemProps> = [
  {name: 'Location Query', icon: HiOutlineLocationMarker,
    to: '/location/classify', permission: Permission.LOCATION_MENU_ITEM_SNOW},
  {name: 'Location Search', icon: HiOutlineLocationMarker,
    to: '/location/autocomplete', permission: Permission.LOCATION_MENU_ITEM_SNOW},
  {
    name: 'Opening Hours',
    icon: HiCalendar,
    to: '/opening-hours/',
    permission: Permission.OPENING_HOURS_MENU_ITEM_SNOW,
  },
  {
    name: 'Language Tools',
    icon: AiOutlineFileSearch,
    to: '/language-tools/',
    permission: Permission.LANGUAGE_TOOLS_MENU_ITEM_SNOW,
  },
  {
    name: 'Chatbots',
    icon: BiConversation,
    to: '/chatbots/',
    permission: Permission.LANGUAGE_TOOLS_MENU_ITEM_SNOW,
  },
]


const Sidebar: FunctionComponent<Props> = ({isExpanded, setExpanded}) => {  
  return (
    <Box bg={'black'} color={'white'} w={isExpanded ? SIDEBAR_WIDTH_EXPANDED : SIDEBAR_WIDTH_COLLAPSED} minH='calc(100vh)'>
      <Flex position={'fixed'} w={isExpanded ? SIDEBAR_WIDTH_EXPANDED : SIDEBAR_WIDTH_COLLAPSED} h={'full'} flexDirection={'column'}
            justifyContent={'space-between'}>
        <Box>
          <Flex mt={4} alignItems='center' mx={isExpanded ? '5' : 2} mb={4}>
            <Image src={isExpanded ? logoInverted : logoInverted} h={6} w={6} />
            {isExpanded && (
              <>
                <Text ml={2} color={'red.300'} fontWeight={'bold'}>
                  indoqa.
                </Text>
                <Text>demos</Text>
              </>
            )}
          </Flex>
          <Box borderTop='1px' borderColor='gray.700' w={'full'}>
            <SidebarLink icon={FiHome} href={'/admin/home'} expanded={isExpanded}>
              Overview
            </SidebarLink>
            {LinkItems.filter(item => authenticator.hasPermission(item.permission)).map(link => (
              <SidebarLink key={link.name} icon={link.icon} href={link.to} expanded={isExpanded}>
                {link.name}
              </SidebarLink>
            ))}
          </Box>
        </Box>
        <Box>
          <Box borderTop='1px' borderColor='gray.700' w={'full'}>
            <SidebarLink icon={FiUser} href={'/admin/account'} expanded={isExpanded}>
              Account
            </SidebarLink>
          </Box>
          <Box borderTop='1px' borderColor='gray.700' w={'full'}>
            <SidebarItem icon={isExpanded ? AiOutlineDoubleLeft : AiOutlineDoubleRight} isActive={false}
                         expanded={isExpanded} onClick={() => setExpanded(!isExpanded)}>
              Toggl Sidebar
            </SidebarItem>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

interface SidebarLinkProps extends FlexProps {
  icon: IconType
  children: ReactText
  href: string
  expanded: boolean
}

const SidebarLink = ({icon, children, href, expanded}: SidebarLinkProps) => {
  return (
    <NavLink to={href} style={{textDecoration: 'none'}}>
      {({isActive}) => (
        <SidebarItem
          expanded={expanded}
          icon={icon}
          isActive={isActive}
          children={children}
        />
      )}
    </NavLink>
  )
}

interface SidebarItemProps extends FlexProps {
  icon: IconType
  children: ReactText
  expanded: boolean
  isActive: boolean
  onClick?: () => void
}

const SidebarItem = ({icon, children, expanded, isActive, onClick}: SidebarItemProps) => {
  return (
    <Flex
      onClick={onClick}
      align='center'
      minHeight={'40px'}
      p={'2'}
      mx={expanded ? 4 : 1}
      my={2}
      borderRadius={'sm'}
      role={'group'}
      cursor={'pointer'}
      _hover={{
        bg: isActive ? 'red.400' : 'gray.700',
      }}
      bg={isActive ? 'red.400' : 'transparent'}
      color={'white'}
      fontSize={'xs'}
    >
      {icon && (
        <Icon
          mr={expanded ? 3 : 0}
          fontSize='16'
          _groupHover={{
            color: 'white',
          }}
          as={icon}
        />
      )}
      {expanded && children}
    </Flex>
  )
}

export default Sidebar
