// tslint:disable
/**
 * IndoqaDemosAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders } from '../runtime';
import type {
    ChatbotAnswer,
    ChatbotInput,
} from '../models';

export interface GetInteractionRequest {
    conversationId: string;
    interactionId: string;
}

export interface PostUserInputToChatbotRequest {
    chatbotName: string;
    chatbotInput?: ChatbotInput;
}

/**
 * no description
 */
export class ChatbotApi extends BaseAPI {

    /**
     * Get the details of an interaction
     */
    getInteraction({ conversationId, interactionId }: GetInteractionRequest): Observable<object>
    getInteraction({ conversationId, interactionId }: GetInteractionRequest, opts?: OperationOpts): Observable<AjaxResponse<object>>
    getInteraction({ conversationId, interactionId }: GetInteractionRequest, opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
        throwIfNullOrUndefined(conversationId, 'conversationId', 'getInteraction');
        throwIfNullOrUndefined(interactionId, 'interactionId', 'getInteraction');

        return this.request<object>({
            url: '/conversations/{conversationId}/interactions/{interactionId}'.replace('{conversationId}', encodeURI(conversationId)).replace('{interactionId}', encodeURI(interactionId)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Post a user input to the chatbot
     */
    postUserInputToChatbot({ chatbotName, chatbotInput }: PostUserInputToChatbotRequest): Observable<ChatbotAnswer>
    postUserInputToChatbot({ chatbotName, chatbotInput }: PostUserInputToChatbotRequest, opts?: OperationOpts): Observable<AjaxResponse<ChatbotAnswer>>
    postUserInputToChatbot({ chatbotName, chatbotInput }: PostUserInputToChatbotRequest, opts?: OperationOpts): Observable<ChatbotAnswer | AjaxResponse<ChatbotAnswer>> {
        throwIfNullOrUndefined(chatbotName, 'chatbotName', 'postUserInputToChatbot');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<ChatbotAnswer>({
            url: '/chatbot/{chatbotName}'.replace('{chatbotName}', encodeURI(chatbotName)),
            method: 'POST',
            headers,
            body: chatbotInput,
        }, opts?.responseOpts);
    };

}
