// tslint:disable
/**
 * IndoqaDemosAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface LanguageToolsInput
 */
export interface LanguageToolsInput {
    /**
     * @type {string}
     * @memberof LanguageToolsInput
     */
    input?: string;
    /**
     * @type {string}
     * @memberof LanguageToolsInput
     */
    mode?: LanguageToolsInputModeEnum;
    /**
     * @type {string}
     * @memberof LanguageToolsInput
     */
    category?: string;
    /**
     * @type {number}
     * @memberof LanguageToolsInput
     */
    page?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum LanguageToolsInputModeEnum {
    Basic = 'BASIC',
    Lt = 'LT'
}

