import React, {FunctionComponent, useCallback, useState} from 'react'
import {LocationApiClient} from '../../../apis'
import {LocationClassificationInput, LocationClassificationResult} from '../../../apis/indoqa-demos'
import LocationClassificationForm from '../components/LocationClassificationForm'
import LocationClassificationResultTerms from '../components/LocationClassificationResultTerms'
import ErrorCard from '../../../components/ErrorCard'
import {debounceTime, Observer} from 'rxjs'

const LocationClassificationPage: FunctionComponent = () => {
  const [result, setResult] = useState<LocationClassificationResult | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)

  const submit = useCallback((input: string, clearSubmitting: () => void) => {
    if (input.length === 0) {
      clearSubmitting()
      setResult(undefined)
      setError(undefined)
      return
    }

    const observer: Observer<LocationClassificationResult> = {
      next: value => {
        setResult(value)
        setError(undefined)
        clearSubmitting()
      },
      error: error => {
        setResult(undefined)
        setError(error)
        clearSubmitting()
      },
      complete: () => {}
    }

    LocationApiClient.classify({locationClassificationInput: {input}}).pipe(
      debounceTime(300)
    ).subscribe(observer)


  }, [])

  return (
    <>
      <LocationClassificationForm onSubmit={submit} />
      {result && (
        <>
          <LocationClassificationResultTerms title={'Terms'} terms={result.terms} />
          <LocationClassificationResultTerms title={'Combined Terms'} terms={result.combinedTerms} />
        </>
      )}
      {error && (
        <ErrorCard error={error} />
      )}
    </>
  )
}

export default LocationClassificationPage
