import React, {FunctionComponent} from 'react'
import {Box} from '@chakra-ui/react'
import {LanguageToolsResult} from '../../../apis/indoqa-demos'

interface Props {
  result?: LanguageToolsResult
  term: string
}

const OeamtcGisSearchSummary: FunctionComponent<Props> = ({result, term}: Props) => {
  return (
    <Box mt={8} mx={4}>
      {result && result.found && result.found > 0 && (
        <div className="mt-2 mb-4">
          todo show paging
        </div>
      )}
    </Box>
  )
}

export default OeamtcGisSearchSummary
