import React, {FunctionComponent} from 'react';
import {LocationAutocompleteResult, LocationAutocompleteScenarioTypeEnum} from '../../../apis/indoqa-demos'
import {Box} from '@chakra-ui/react'
import Card from '../../../components/Card'

interface Props {
    result?: LocationAutocompleteResult
}

const LocationAutocompleteHint: FunctionComponent<Props> = ({result}) => (
  <>
    {result && result.scenarios && result.scenarios.length > 0 &&  (
      <Box my={2} backgroundColor={'gray.100'} fontSize={'xs'}>
        <Card>
          {result.scenarios.filter((item, index) => index === 0).find(value => value.type === LocationAutocompleteScenarioTypeEnum.FixedStreet) && (
            <Box> Straße <b>{result.scenarios[0].context} </b>erkannt, Suche
              nach <i>"{result.scenarios[0].query}"</i></Box>
          )}
          {result.scenarios.filter((item, index) => index === 0).find(value => value.type === LocationAutocompleteScenarioTypeEnum.OnlyZip) && (
            <Box> Postleitzahl <b>{result.scenarios[0].context} </b>erkannt, suchen Sie weiter nach Straßen, Pois
              etc.</Box>
          )}
          {result.scenarios.filter((item, index) => index === 0).find(value => value.type === LocationAutocompleteScenarioTypeEnum.OnlyStreet) && (
            <Box> Straße <b>{result.scenarios[0].context}</b> erkannt, schränken Sie mit PLZ/Ort ein oder fügen
              Sie eine Hausnummer hinzu </Box>
          )}
          {result.scenarios.filter((item, index) => index === 0).find(value => value.type === LocationAutocompleteScenarioTypeEnum.OnlyCity) && (
            <Box> Ort <b>{result.scenarios[0].context}</b> erkannt, suchen Sie weiter nach Straßen, Pois
              etc.</Box>
          )}
          {result.scenarios.filter((item, index) => index === 0).find(value => value.type === LocationAutocompleteScenarioTypeEnum.UnknownNumberZip) && (
            <Box> Mögliche PLZ beginnend mit <i>"{result.scenarios[0].query}"</i> erkannt</Box>
          )}
          {result.scenarios.filter((item, index) => index === 0).find(value => value.type === LocationAutocompleteScenarioTypeEnum.FixedZip) && (
            <Box> Postleitzahl <b>{result.scenarios[0].context} </b>erkannt, Suche
              nach <i>"{result.scenarios[0].query}"</i></Box>
          )}
          {result.scenarios.filter((item, index) => index === 0).find(value => value.type === LocationAutocompleteScenarioTypeEnum.UnknownString) && (
            <Box> Schränken Sie nach Ort, PLZ, POI etc. ein</Box>
          )}

          {result.scenarios.filter((item, index) => index === 0).find(value => value.type === LocationAutocompleteScenarioTypeEnum.FixedCity) && (
            <Box> Ort <b>{result.scenarios[0].context} </b>erkannt, Suche
              nach <i>"{result.scenarios[0].query}"</i></Box>
          )}

          {result.scenarios.filter((item, index) => index === 0).find(value => value.type === LocationAutocompleteScenarioTypeEnum.FixedPlace) && (
            <Box> PLZ+Ort <b>{result.scenarios[0].context} </b>erkannt, Suche
              nach <i>"{result.scenarios[0].query}"</i></Box>
          )}

          {result.scenarios.filter((item, index) => index === 0).find(value => value.type === LocationAutocompleteScenarioTypeEnum.OnlyPlace) && (
            <Box> PLZ+Ort <b>{result.scenarios[0].context} </b>erkannt, suchen Sie weiter nach Straßen, Pois
              etc.</Box>
          )}

          {result.scenarios.filter((item, index) => index === 0).find(value => value.type === LocationAutocompleteScenarioTypeEnum.OnlyZipStreet) && (
            <Box> PLZ+Straße <b>{result.scenarios[0].context} </b>erkannt, ergänzen Sie eine Hausnummer</Box>
          )}

          {result.scenarios.filter((item, index) => index === 0).find(value => value.type === LocationAutocompleteScenarioTypeEnum.FixedZipStreet) && (
            <Box> PLZ+Straße <b>{result.scenarios[0].context} </b>erkannt, Suche
              nach <i>"{result.scenarios[0].query}"</i></Box>
          )}
        </Card>
      </Box>
    )}
  </>
)

export default LocationAutocompleteHint