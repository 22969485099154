import React, {FunctionComponent} from 'react'
import TopCard from '../../../components/TopCard'
import {authenticator} from '../../../app/authenticator'
import {LinkItems} from '../../../components/Sidebar'
import {NavLink} from 'react-router-dom'
import {Container, Flex, Icon, StackDivider, VStack} from '@chakra-ui/react'


const AdminHomePage: FunctionComponent = () => (
  <>
    <TopCard
      title={'Indoqa Demos Overview'}
    >
    </TopCard>
    <Container maxW={'container.lg'} pt={12}>
      <TopCard title={'Available Demos'}>
        <VStack divider={<StackDivider borderColor='gray.200' />} spacing={4}
                align='stretch'>
          {LinkItems.filter(item => authenticator.hasPermission(item.permission)).map(link => (
            <NavLink to={link.to} key={link.name}>
              <Flex alignItems={'center'}>
                <Icon
                  mr={3}
                  fontSize='36'
                  _groupHover={{
                    color: 'white',
                  }}
                  color={'red.00'}
                  as={link.icon}
                />
                {link.name}
              </Flex>
            </NavLink>
          ))}
        </VStack>
      </TopCard>
    </Container>

  </>
)

export default AdminHomePage
