import {ChatbotApi, Configuration, LanguageToolsApi, LocationApi, OpeningHoursApi} from './indoqa-demos'
import {authenticator} from '../app/authenticator'
import {AjaxConfig} from 'rxjs/ajax'

const config = new Configuration({
  basePath: process.env.REACT_APP_API_BASE_URL,
  middleware: [
    {
      pre: (request: AjaxConfig): AjaxConfig => ({
        ...request,
        headers: {
          ...request.headers,
          Authorization: `Bearer ${authenticator.getToken()}`,
        },
      }),
    },
  ],
})

export const LanguageToolsApiClient = new LanguageToolsApi(config)
export const LocationApiClient = new LocationApi(config)
export const OpeningHoursClient = new OpeningHoursApi(config)

export const ChatbotClient = new ChatbotApi(config)
