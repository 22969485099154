import React, {FunctionComponent, useCallback} from 'react'
import {Box, Button, MenuItem, Tooltip} from '@chakra-ui/react'
import {Example} from '../examples'

export interface ExampleItemProps {
  onSelect: (value: string) => void
  example: Example
}

const ExampleItem = ({onSelect, example}: ExampleItemProps) => {
  const select = useCallback(() => {
    onSelect(example.term)
  }, [])

  return (
    <Tooltip label={example.term}>
      <Box mb={4}>
        <Button title={example.name} onClick={select} variant={'ghost'}>
          {' '}
          {example.name}{' '}
        </Button>
      </Box>
    </Tooltip>
  )
}
export default ExampleItem
