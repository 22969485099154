import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { LocationApiClient } from "../../../apis";
import { Coordinate, LocationAutocompleteResult, LocationAutocompleteResultItem } from "../../../apis/indoqa-demos";
import { Observer } from "rxjs";
import LocationAutocompleteForm from "../components/LocationAutocompleteForm";
import MapSelector from "../components/MapSelector";

declare var idqsa: any;


const LocationAutocompletePage: FunctionComponent = () => {
  const [result, setResult] = useState<LocationAutocompleteResult | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [coordinate, setCoordinate] = useState<Coordinate | undefined>(undefined);
  const [pin, setPin] = useState<LocationAutocompleteResultItem | undefined>(undefined);
  const [selectedItem, setSelectedItem] = useState<LocationAutocompleteResultItem | undefined>(undefined);

  const onBoundingBoxUpdate = useCallback((minLongitude: number, minLatitude: number,
                                           maxLongitude: number, maxLatitude: number) => {
    console.log(`Updated bounding box: ${minLongitude} ${minLatitude} - ${maxLongitude} ${maxLatitude}`);
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => setCoordinate({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      }),
      err => setCoordinate({
        latitude: 48,
        longitude: 16
      })
    );
  }, []);

  const submit = useCallback((input: string, clearSubmitting: () => void) => {
    let term = input;

    if (selectedItem && selectedItem.type === "STREET") {
      term = selectedItem.zip + " " + selectedItem.name + " " + input;
    } else if (selectedItem && selectedItem.type === "ZIP") {
      term = selectedItem.zip + " " + selectedItem.city + " " + input;
    }

    if (term.length === 0) {
      clearSubmitting();
      setResult(undefined);
      setError(undefined);
      return;
    }

    const observer: Observer<LocationAutocompleteResult> = {
      next: value => {
        setResult(value);
        setError(undefined);
        clearSubmitting();
      },
      error: error => {
        setResult(undefined);
        setError(error);
        clearSubmitting();
      },
      complete: () => {
      }
    };

    const clientInfo = idqsa("clientInfo");
    console.log("clientInfo", clientInfo);


    LocationApiClient.autocompleteLocation({
        sessionId: clientInfo ? clientInfo.sessionId : 'sessionIdUnknown',
        tabId: clientInfo ? clientInfo.tabId : '',
        locationAutocompleteInput: {
          input: term,
          coordinate
        }
      }
    ).subscribe(observer);
  }, [coordinate, coordinate?.latitude, coordinate?.longitude, selectedItem, idqsa]);

  const select = useCallback((item: LocationAutocompleteResultItem) => {
    const clientInfo = idqsa("clientInfo");
    console.log("clientInfo", clientInfo);


    LocationApiClient.geocodeLocation({
      ...item,
      sessionId: clientInfo.sessionId,
      tabId: clientInfo.tabId,
    }).subscribe(value => {
      if (value && value.length > 0) {
        setPin(value[0]);
      }
    });
  }, [setPin]);


  return (
    <>
      <LocationAutocompleteForm
        onSubmit={submit}
        onSelect={select}
        result={result}
        scenarios={result && result.scenarios!}
        selectedItem={selectedItem} deselectItem={() => setSelectedItem(undefined)}
      />
      <MapSelector
        onBoundingBoxUpdate={onBoundingBoxUpdate}
        coordinate={coordinate!}
        setCoordinate={setCoordinate}
        pin={pin}
      />
    </>
  );
};

export default LocationAutocompletePage;
