import React, {FunctionComponent} from 'react'
import {Box, Flex, Heading, HStack, Stack, StackDivider} from '@chakra-ui/react'

interface Props {
  title?: string
  menu?: React.ReactNode
  children: React.ReactNode
}

const TopCard: FunctionComponent<Props> = ({title, children, menu}) => (
  <Stack boxShadow="lg" borderRadius="sm">
    <HStack  alignItems={'center'} borderBottom='1px' borderColor='gray.200' divider={<StackDivider m={2} borderColor='gray.200' />} spacing={4}>
      <Heading p="4" size="md"> {title} </Heading>
      {menu && (
        <Box mr={2}>
          {menu}
        </Box>
      )}
    </HStack>
    {children && (
      <Box p={4}>
        {children}
      </Box>
    )}
  </Stack>
)

export default TopCard
