import React, {FunctionComponent, useEffect, useState} from 'react'
import {useParams} from 'react-router'
import OeamtcGisSearchSummary from '../components/OeamtcGisSearchSummary'
import OeamtcGisSearchPaging from '../components/OeamtcGisSearchPaging'
import OeamtcGisSearchFacets from '../components/OeamtcGisSearchFacets'
import OeamtcGisSearchResults from '../components/OeamtcGisSearchResults'
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
} from '@chakra-ui/react'
import {useNavigate} from 'react-router-dom'
import {Field, Form, Formik} from 'formik'
import TopCard from '../../../components/TopCard'
import {ChevronDownIcon} from '@chakra-ui/icons'
import {LanguageToolsInput, LanguageToolsInputModeEnum, LanguageToolsResult} from '../../../apis/indoqa-demos'
import {LanguageToolsApiClient} from '../../../apis'

const LanguageToolsSearchPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const {termParameter, pageParameter, categoryParameter, searchModeParameter} = useParams()

  const [result, setResult] = useState<LanguageToolsResult>({})

  useEffect(() => {
    if (!termParameter || termParameter.length === 0) {
      setResult({})
      return
    }

    window.document.title = `${termParameter} | Language Tools Search`

    const request: LanguageToolsInput = {
      input: termParameter,
      page: parseInt(pageParameter!),
      category: categoryParameter !== 'all' ? categoryParameter : undefined,
      mode: searchModeParameter as LanguageToolsInputModeEnum,
    }

    LanguageToolsApiClient.search({
      instance: 'oeamtc-gis',
      languageToolsInput: request,
    }).subscribe(setResult)

    window.scrollTo(0, 0)
  }, [termParameter, pageParameter, categoryParameter, searchModeParameter])

  const search = ({term}: any, {setSubmitting}: any) => {
    navigate(term.length > 0 ? `/language-tools/search/${searchModeParameter}/all/1/${term}` : '/language-tools/search')
    setSubmitting(false)
  }

  const changePage = (newPage: number) => {
    navigate(`/language-tools/search/${searchModeParameter}/${categoryParameter || 'all'}/${newPage}/${termParameter}`)
  }

  const changeSearchMode = (searchMode: LanguageToolsInputModeEnum) => {
    navigate(`/language-tools/search/${searchMode}/${categoryParameter || 'all'}/1/${termParameter}`)
  }

  const selectCategory = (category: string) => {
    navigate(`/language-tools/search/${searchModeParameter}/${category}/1/${termParameter}`)
  }

  return (
    <>
      <Formik initialValues={{term: termParameter}} onSubmit={search}>
        {({isSubmitting, setFieldValue}) => (
          <Form>
            <TopCard title={'Language Tools Fulltext Search'} menu={
              <Stack direction={{base: 'column', md: 'row'}}>
                <Menu>
                  <MenuButton as={Button} variant={'ghost'} size={'sm'} rightIcon={<ChevronDownIcon />}>
                    oeamtc-gis
                  </MenuButton>
                  <MenuList minWidth='240px'>
                    <MenuOptionGroup defaultValue={searchModeParameter} title='Data' type='radio'>
                      <MenuItemOption value={LanguageToolsInputModeEnum.Basic}>ÖAMTC GIS POI</MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
                <Button aria-label={'Examples'} variant='ghost' size={'sm'}>
                  Examples
                </Button>
              </Stack>
            }>
              <Stack direction={{base: 'column', md: 'row'}} justifyContent='space-between'>
                <Field name='term' as={Input} className='form-control' type='text' placeholder={'Enter search term'} />
                <Stack direction={{base: 'column', md: 'row'}}>
                  <Menu>
                    <MenuButton as={Button} variant={'outline'} rightIcon={<ChevronDownIcon />}>
                      {searchModeParameter}
                    </MenuButton>
                    <MenuList minWidth='240px'>
                      <MenuOptionGroup defaultValue={searchModeParameter} title='Search' type='radio'>
                        <MenuItemOption value={LanguageToolsInputModeEnum.Basic}
                                        onClick={() => changeSearchMode(LanguageToolsInputModeEnum.Basic)}>
                          Basic
                        </MenuItemOption>
                        <MenuItemOption value={LanguageToolsInputModeEnum.Lt}
                                        onClick={() => changeSearchMode(LanguageToolsInputModeEnum.Lt)}>
                          Language Tools
                        </MenuItemOption>
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                  <Button type='submit' colorScheme={'green'} disabled={isSubmitting}>
                    Search
                  </Button>
                </Stack>
              </Stack>
            </TopCard>
          </Form>
        )}
      </Formik>
      <Container maxW={'container.lg'} pt={12}>
        <OeamtcGisSearchSummary result={result} term={termParameter!} />
        <Flex>
          <Box>
            <OeamtcGisSearchResults result={result} />
            <OeamtcGisSearchPaging result={result} onPageChange={changePage} currentPage={pageParameter!} />
          </Box>
          <Box>
            <OeamtcGisSearchFacets result={result} onSelect={selectCategory} category={categoryParameter!} />
          </Box>
        </Flex>
      </Container>
    </>
  )
}

export default LanguageToolsSearchPage
