import Keycloak, {KeycloakConfig, KeycloakInitOptions, KeycloakTokenParsed} from 'keycloak-js'
import {AuthenticatedUser, Authenticator, Permission} from './Authenticator'
import {getAuthConfig} from './getAuthConfig'

interface IndoqaKeycloakAccessTokenParsed extends KeycloakTokenParsed {
  sub: string
  name: string
  email: string
  preferred_username: string
}

interface IndoqaKeycloakIdTokenParsed extends IndoqaKeycloakAccessTokenParsed {
  groups?: string[]
}

const getKeycloakConfig = (): KeycloakConfig => {
  const authConfig = getAuthConfig()
  if (!authConfig) {
    throw new Error('The configuration is not available.')
  }
  const {url, realm, clientId} = authConfig
  if (!url) {
    throw new Error('The URL is not available.')
  }
  if (!realm) {
    throw new Error('The realm is not available.')
  }
  if (!clientId) {
    throw new Error('The clientId is not available.')
  }
  return {
    url,
    realm,
    clientId,
  }
}

export class KeycloakAuthenticator implements Authenticator {
  private readonly keycloak: Keycloak
  private readonly config: KeycloakConfig
  private initialized: boolean
  private readonly applicationGroup?: string

  constructor(applicationGroup?: string) {
    this.config = getKeycloakConfig()
    this.keycloak = new Keycloak(this.config)
    this.initialized = false
    this.applicationGroup = applicationGroup

    // Error handling can be tested by removing valid values from the Keycloak client's list of Web Origin
    this.keycloak.onAuthError = (e) => {
      const msg = `Authentication at ${this.config.url} failed. (onAuthError)`
      console.error(msg, e)
      this.keycloak.logout()
    }
    this.keycloak.onAuthRefreshError = () => {
      const msg = `Authentication token refresh failed. (onAuthRefreshError)`
      console.error(msg)
      this.keycloak.logout()
    }
  }

  init(done: (authenticated: boolean) => void) {
    const initOptions: KeycloakInitOptions = {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/check-login.html`,
      enableLogging: true,
    }
    this.keycloak
      .init(initOptions)
      .then((authenticated) => {
        // console.log('Keycloak has been initialized. accessToken -> ', this.getToken())
        this.initialized = true

        if (authenticated) {
          // console.log('Keycloak has successfully authenticated the user.', this.keycloakAdapterInstance)
          if (this.applicationGroup !== undefined && !this.hasGroup(this.applicationGroup)) {
            const {username} = this.keycloak.idTokenParsed as IndoqaKeycloakIdTokenParsed
            console.error(`The user ${username} is not allowed to access the Indoqa Search-Workbench.`)
            this.logout()
            return
          }
          setInterval(() => {
            this.keycloak
              .updateToken(10)
              .then((result) => {
              })
              .catch((e) => {
                console.error('Error while refreshing the authentication token.', e)
                this.keycloak.logout()
              })
          }, 10_000)
        }
        done(this.getToken() !== undefined)
      })
      .catch((e) => {
        console.error('Indoqa SSO failed.', e)
        done(false)
      })
  }

  isInitialized(): boolean {
    return this.initialized
  }

  getToken(): string | undefined {
    if (!this.isAuthenticated()) {
      return undefined
    }
    return this.keycloak.token
  }

  getTokenType(): string | undefined {
    return 'Bearer'
  }

  hasGroup(expectedGroup: string): boolean {
    if (!expectedGroup) {
      return false
    }
    const idToken = this.keycloak.idTokenParsed as IndoqaKeycloakIdTokenParsed
    if (idToken) {
      const {groups} = idToken
      if (groups && Array.isArray(groups) && groups.includes(expectedGroup)) {
        return true
      }
    } else {
      console.error('There is no identity token available.')
    }
    return false
  }

  hasRole(expectedRole: string): boolean {
    if (!expectedRole) {
      return false
    }
    const idToken = this.keycloak.idTokenParsed
    if (idToken) {
      const {realm_access} = idToken
      if (
        realm_access &&
        realm_access.roles &&
        Array.isArray(realm_access.roles) &&
        realm_access.roles.includes(expectedRole)
      ) {
        return true
      }
    } else {
      console.error('There is no identity token available')
    }
    return false
  }

  hasPermission(permission: Permission) {
    if (permission === Permission.LANGUAGE_TOOLS_MENU_ITEM_SNOW) {
      return this.hasGroup('/indoqa-demos/language-tools')
    }
    if (permission === Permission.LOCATION_MENU_ITEM_SNOW) {
      return this.hasGroup('/indoqa-demos/location')
    }
    if (permission === Permission.OPENING_HOURS_MENU_ITEM_SNOW) {
      return this.hasGroup('/indoqa-demos/opening-hours')
    }
    if (permission === Permission.CHATBOT_MENU_ITEM_SNOW) {
      return this.hasGroup('/indoqa-demos/chatbot')
    }
    return false
  }

  isAuthenticated(): boolean {
    if (!this.isInitialized()) {
      return false
    }
    return this.keycloak.authenticated === true
  }

  login(): void {
    if (!this.isInitialized()) {
      return
    }
    const redirectUri = window.location.origin
    this.keycloak.login({redirectUri})
  }

  logout(): void {
    if (!this.isInitialized()) {
      return
    }
    this.keycloak.logout()
  }

  currentUser(): AuthenticatedUser | undefined {
    if (!this.isAuthenticated()) {
      return undefined
    }
    const tokenParsed = this.keycloak.idTokenParsed as IndoqaKeycloakAccessTokenParsed | undefined
    if (!tokenParsed) {
      return undefined
    }

    const {sub, name, email, preferred_username} = tokenParsed
    return {
      id: sub,
      email,
      name,
      username: preferred_username,
    }
  }
}
