import React, {FunctionComponent} from 'react'
import {Route, Routes} from 'react-router-dom'

import LocationClassificationPage from './pages/LocationClassificationPage'
import LocationAutocompletePage from './pages/LocationAutocompletePage'

const LocationModule: FunctionComponent = () => {
  return (
    <Routes>
      <Route path={'/classify'} element={<LocationClassificationPage />} />
      <Route path={'/autocomplete'} element={<LocationAutocompletePage />} />
    </Routes>
  )
}

export default LocationModule
