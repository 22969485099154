import React, {FunctionComponent} from 'react'
import TopCard from '../../../components/TopCard'
import {Box, Button, Container} from '@chakra-ui/react'
import {authenticator} from '../../../app/authenticator'


const AdminHomePage: FunctionComponent = () => (
  <>
    <TopCard
      title={'Account'}
    >
    </TopCard>
    <Container maxW={'container.lg'} pt={12}>
      <TopCard title={'User Details'} menu={[
        <Button onClick={() => authenticator.logout()} size={'xs'} colorScheme={"red"}>Logout</Button>
      ]}>
        <Box> Username: {authenticator.currentUser()!.username}</Box>
        <Box> Email: {authenticator.currentUser()!.email}</Box>
      </TopCard>
    </Container>

  </>
)

export default AdminHomePage
