// tslint:disable
/**
 * IndoqaDemosAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    AutocompleteResultItem,
    LanguageToolsInput,
    LanguageToolsResult,
    LanguageToolsResultItem,
} from '../models';

export interface AutocompleteRequest {
    instance: string;
    term: string;
}

export interface GetDetailRequest {
    instance: string;
    id: string;
}

export interface ImportDataRequest {
    instance: string;
    clearIndex: boolean;
    languageToolsResultItem?: Array<LanguageToolsResultItem>;
}

export interface SearchRequest {
    instance: string;
    languageToolsInput?: LanguageToolsInput;
}

/**
 * no description
 */
export class LanguageToolsApi extends BaseAPI {

    /**
     * Autocomplete
     */
    autocomplete({ instance, term }: AutocompleteRequest): Observable<Array<AutocompleteResultItem>>
    autocomplete({ instance, term }: AutocompleteRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AutocompleteResultItem>>>
    autocomplete({ instance, term }: AutocompleteRequest, opts?: OperationOpts): Observable<Array<AutocompleteResultItem> | AjaxResponse<Array<AutocompleteResultItem>>> {
        throwIfNullOrUndefined(instance, 'instance', 'autocomplete');
        throwIfNullOrUndefined(term, 'term', 'autocomplete');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'term': term,
        };

        return this.request<Array<AutocompleteResultItem>>({
            url: '/language-tools/{instance}/autocomplete'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Get detail
     */
    getDetail({ instance, id }: GetDetailRequest): Observable<LanguageToolsResultItem>
    getDetail({ instance, id }: GetDetailRequest, opts?: OperationOpts): Observable<AjaxResponse<LanguageToolsResultItem>>
    getDetail({ instance, id }: GetDetailRequest, opts?: OperationOpts): Observable<LanguageToolsResultItem | AjaxResponse<LanguageToolsResultItem>> {
        throwIfNullOrUndefined(instance, 'instance', 'getDetail');
        throwIfNullOrUndefined(id, 'id', 'getDetail');

        return this.request<LanguageToolsResultItem>({
            url: '/language-tools/{instance}/detail/{id}'.replace('{instance}', encodeURI(instance)).replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Import data
     */
    importData({ instance, clearIndex, languageToolsResultItem }: ImportDataRequest): Observable<void>
    importData({ instance, clearIndex, languageToolsResultItem }: ImportDataRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>>
    importData({ instance, clearIndex, languageToolsResultItem }: ImportDataRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>> {
        throwIfNullOrUndefined(instance, 'instance', 'importData');
        throwIfNullOrUndefined(clearIndex, 'clearIndex', 'importData');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'clearIndex': clearIndex,
        };

        return this.request<void>({
            url: '/language-tools/{instance}/import'.replace('{instance}', encodeURI(instance)),
            method: 'POST',
            headers,
            query,
            body: languageToolsResultItem,
        }, opts?.responseOpts);
    };

    /**
     * Search
     */
    search({ instance, languageToolsInput }: SearchRequest): Observable<LanguageToolsResult>
    search({ instance, languageToolsInput }: SearchRequest, opts?: OperationOpts): Observable<AjaxResponse<LanguageToolsResult>>
    search({ instance, languageToolsInput }: SearchRequest, opts?: OperationOpts): Observable<LanguageToolsResult | AjaxResponse<LanguageToolsResult>> {
        throwIfNullOrUndefined(instance, 'instance', 'search');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<LanguageToolsResult>({
            url: '/language-tools/{instance}/search'.replace('{instance}', encodeURI(instance)),
            method: 'POST',
            headers,
            body: languageToolsInput,
        }, opts?.responseOpts);
    };

}
